export default class Environment {
   static get API_ORIGIN() {
   		const env = process.env.NODE_ENV;
        var result = '';
        switch(env) {
          case 'produc':
            result = 'https://next.trycode.jp/';
            break;
          case 'stage':
            result = 'https://stage2.trycode.jp/';
            break;
          default:
            result = 'http://localhost:9906/';
        }
        return result;
   }
}