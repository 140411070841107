import env from '../environment.js';

export default (filename, blob) => {
    const axiosBase = require('axios');
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const questionId = params.get('q');
    
    //IE11 support
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {// other browsers
        //blob to file
        var file = new File([blob], filename);
        const fd = new FormData();
        fd.append('file', file);
        fd.append('qi', questionId);
        fd.append('cok', getCookie("texid"));
        
        if (window.confirm("問題のコンテンツを準備してもよろしいですか？")) {
            const FETCH_API = env.API_ORIGIN;

            axiosBase.post(FETCH_API + "next-trycode-api/scratch/prepareQuesOfferContent", fd, {withCredentials: true})
            .then(res => {
                    alert(res.data)
            });
        }
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

};
