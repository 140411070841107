import env from '../environment.js';

export default (filename, blob) => {
    const axiosBase = require('axios');
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const takeTrainingId = params.get('tki');
    
    //IE11 support
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {// other browsers
        //blob to file
        var file = new File([blob], filename);
        const fd = new FormData();
        fd.append('file', file);
        fd.append('tki', takeTrainingId);
        
        const FETCH_API = env.API_ORIGIN;
        
        axiosBase.post(FETCH_API + "next-trycode-api/scratch/saveFileHistories", fd, {withCredentials: true})
        .then(res => {
                parent.postMessage(res.data, "*");
        });
        
    }

};
